export default class ProcessadorMapeamento {
    constructor({mapaMapeamentos}) {
        this.mapaMapeamentos = mapaMapeamentos;
    }

    processar({tela, dados, atribuicoes}) {
        Object.entries(dados).forEach(([nome, valor]) => {
            let campo = tela.campos[nome];
    
            let processador = this.mapaMapeamentos[campo.tipo];
            processador.processar({campo, atribuicoes, dadoCampo: valor});                        
        });        
    }    
}