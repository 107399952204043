<template>
  <v-select :label="campo.rotulo" :items="itens" :hint="campo.ajuda" filled :value="value" @input="updateValue" />
</template>

<script>
export default {
    name: 'caixa-de-selecao',
    props: {
      value: {
        type: String        
      },
      campo: {
        type: Object,
        required: true
      }      
    },
    data() {
      return {
        itens: []
      }
    },
    mounted() {
        this.montarItens();
    },
    methods: {
        montarItens() {
            this.campo.opcoes.forEach(opcao => {
                this.itens.push({
                    text: opcao.texto,
                    value: opcao.valor
                })
            });
        },
        updateValue(value) {
            this.$emit("input", value);
        }
    }
}
</script>

<style>

</style>