function preencherZero(num) {
    if (num < 10) {
        return "0" + num;
    } else {
        return num;
    }
}

function formataData(data) {
    const d = new Date(data);
    return (
        preencherZero(d.getDate()) +
        "/" +
        preencherZero(d.getMonth() + 1) +
        "/" +
        d.getFullYear() +
        " " +
        d.getHours() +
        ":" +
        preencherZero(d.getMinutes())
    );
}

function limite(texto, size = 45) {
    if (texto.length <= size) {
        return texto;
    }

    return texto.substring(0, size - 3) + "...";
}

function formataDataParaApi(data) {
    const d = new Date(data);
    return (
        d.getFullYear() +
        "-" +
        preencherZero(d.getMonth() + 1) +
        "-" +
        preencherZero(d.getDate()) +
        " " +
        preencherZero(d.getHours()) +
        ":" +
        preencherZero(d.getMinutes()) +
        ":" +
        preencherZero(d.getSeconds())
    );
}

export const INTERVALO_ATUALIZACAO_MS = 120000; // 2minutes

export default {
    formataData,
    limite,
    formataDataParaApi,
};
