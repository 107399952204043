<template>
    <div>
        <div class="ufv-btn-voltar">
            <v-row>
                <ViewBotaoVoltar :podeVoltar="podeVoltar" @voltar="voltar" />
                <v-spacer />
            </v-row>
        </div>
        <v-card>
            <div class="ufv-container-text">
                <div class="text-h4">Abrir chamado</div>
            </div>
            <v-container>
                <div class="d-flex flex-column pa-4 align-center">
                    <div class="text-wrap">
                        Para resolver seu problema, será necessário criar uma
                        solicitação em nosso sistema com os seguintes dados:
                    </div>
                    <!-- PROPRIEDADES -->
                    <h3 v-if="Object.keys(atribuicoes).length > 0">
                        Propriedades
                    </h3>
                    <v-simple-table dense class="mt-1">
                        <tbody>
                            <tr
                                v-for="(prop, index) in Object.keys(
                                    atribuicoes
                                )"
                                :key="index"
                            >
                                <td
                                    class="text-capitalize font-weight-bold text-right"
                                >
                                    {{ truncate(prop) | capitalize }}
                                </td>
                                <td>{{ truncate(atribuicoes[prop]) }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                    <!-- TABELAS -->
                    <div
                        v-for="(prop, index) in Object.keys(tabelas)"
                        :key="index"
                        class="ma-2 d-flex flex-column align-center"
                    >
                        <h3 class="text-capitalize">{{ prop }}</h3>
                        <v-simple-table dense v-if="tabelas[prop].length > 0">
                            <thead>
                                <tr>
                                    <th
                                        v-for="(valor, index) in Object.keys(
                                            tabelas[prop][0]
                                        )"
                                        :key="index"
                                        class="text-capitalize font-weight-bold"
                                    >
                                        {{ valor }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(linha, index) in tabelas[prop]"
                                    :key="index"
                                >
                                    <td
                                        v-for="(valor, indexLinha) in linha"
                                        :key="indexLinha"
                                    >
                                        {{ valor }}
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <div v-else>Tabela vazia</div>
                    </div>

                    <!-- ANEXOS -->
                    <h3 v-if="anexos.length > 0">Anexos</h3>
                    <v-simple-table dense class="ma-2" v-if="anexos.length > 0">
                        <tbody>
                            <tr v-for="anexo in anexos" :key="anexo.name">
                                <td
                                    class="text-capitalize font-weight-bold text-right"
                                >
                                    {{ truncate(anexo.name) }}
                                </td>
                                <td>{{ truncate(anexo.type) }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>

                    <!-- DIALOGO CRIAR CHAMADO -->
                    <div>
                        <v-dialog v-model="dialogo" persistent max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="criarChamado"
                                    tile
                                    class="pa-6 mt-6"
                                >
                                    <v-icon>mdi-ticket-account</v-icon>
                                    {{ $t("CriarChamado") }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title class="primary white--text">{{
                                    titulo
                                }}</v-card-title>
                                <v-card-text
                                    class="d-flex justify-center pa-16 mt-6"
                                    v-if="loading"
                                >
                                    <v-progress-circular
                                        :size="70"
                                        :width="7"
                                        color="primary"
                                        indeterminate
                                    />
                                </v-card-text>
                                <v-card-text v-else class="mt-6">
                                    <div v-if="!erro">
                                        <p>
                                            Chamado criado com o número de
                                            protocolo <b>{{ tn }}</b
                                            >.
                                        </p>
                                        <p v-if="prazo != null">
                                            O prazo estimado de antendimento é
                                            até <b>{{ prazo }}</b
                                            >.
                                        </p>
                                    </div>
                                    <div v-if="erro">
                                        <p>
                                            Ocorreu um problema em nossos
                                            servidores. Por favor, tente mais
                                            tarde.
                                        </p>
                                    </div>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="processar"
                                        :disabled="loading"
                                        >Ok</v-btn
                                    >
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </div>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import ViewBotaoVoltar from "@/views/atendimento/arvore/elementos/ViewBotaoVoltar";

export default {
    name: "telaFinal",

    components: {
        ViewBotaoVoltar: ViewBotaoVoltar,
    },
    emits: ["voltar"],
    props: {
        tela: {
            type: Object,
            required: true,
        },
        podeVoltar: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            dialogo: false,
            erro: false,
            titulo: "Criando chamado...",
            loading: true,
            tn: null,
            ticketId: null,
            prazo: null,
            atribuicoes: [],
            tabelas: [],
            anexos: [],
        };
    },
    methods: {
        ...mapActions("visualizacoes", ["atualizarVisualizacoes"]),
        voltar() {
            this.$emit("voltar");
        },
        mostrarMsgSucesso(data) {
            this.titulo = "Chamado criado com sucesso";
            this.ticketId = data.ticketId;
            this.tn = data.ticketNumber;
            if (
                data.solutionTimeDestinationDate !== null &&
                data.solutionTimeDestinationDate !== undefined &&
                data.solutionTimeDestinationDate !== ""
            ) {
                let date = new Date(data.solutionTimeDestinationDate);
                this.prazo = date.toLocaleString("pt-BR");
            }
            this.loading = false;
        },
        mostrarMsgErro(error) {
            console.log(error);
            this.titulo = "Falha ao criar chamado";
            this.erro = true;
            this.loading = false;
        },
        criarChamado() {
            this.$servicoAtendimento
                .criarChamado()
                .then((data) => {
                    this.atualizarVisualizacoes();
                    this.mostrarMsgSucesso(data);
                })
                .catch((error) => {
                    this.mostrarMsgErro(error);
                });
        },
        processar() {
            this.$servicoAtendimento.encerrarAtendimento();
            this.$router.push(`/chamados/detalhe/${this.ticketId}`);
        },
        truncate: function (text) {
            if (text.length >= 40) {
                return text.slice(0, 38) + "...";
            } else {
                return text;
            }
        },
        montarAtribuicoes() {
            const filtered = Object.entries(
                this.$store.getters["atendimento/atribuicoes"]
            ).filter(
                ([key, value]) =>
                    key !== "__anexos" &&
                    value !== undefined &&
                    value != null &&
                    value.constructor !== Array
            );
            this.atribuicoes = Object.fromEntries(filtered);
        },
        montarTabelas() {
            const filteredTabelas = Object.entries(
                this.$store.getters["atendimento/atribuicoes"]
            ).filter(
                ([key, value]) =>
                    key !== "__anexos" &&
                    value !== undefined &&
                    value != null &&
                    value.constructor === Array
            );
            this.tabelas = Object.fromEntries(filteredTabelas);
        },
        montarAnexos() {
            if (this.$store.getters["atendimento/atribuicoes"]["__anexos"]) {
                this.anexos =
                    this.$store.getters["atendimento/atribuicoes"]["__anexos"];
            }
        },
    },
    mounted() {
        this.montarAtribuicoes();
        this.montarTabelas();
        this.montarAnexos();
    },
};
</script>
<style scoped>
* {
    word-break: break-word;
}
</style>
