const handle400 = (router) => {
    router.push('/4000');
}

const handle404 = (router) => {
    router.push('/404');
}

const handle500 = (router) => {
    router.push('/500');
}

export const handleError = (router, error) => {
    if (error.response) {
        // A requisição foi feita e o servidor respondeu com um código de status
        // que sai do alcance de 2xx
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);
        switch(error.response.status) {
            case 400:
                handle400(router);
                break;
            case 404:
                handle404(router);
                break;
            default:
                handle500(router);            
        }
        return;
    } else if (error.request) {
        // A requisição foi feita mas nenhuma resposta foi recebida
        // `error.request` é uma instância do XMLHttpRequest no navegador e uma instância de
        // http.ClientRequest no node.js
        console.error(error.request);        
    } else {
        // Alguma coisa acontenceu ao configurar a requisição que acionou este erro.
        console.error('Error', error.message);        
    }    
    console.log(error);
    handle500(router);
}
