<template>
    <v-card fluid class="my-4 pa-4 d-flex flex-sm-column flex-md-row" elevation="1">
        <v-icon x-large class="ma-2" color="blue lighten-3">mdi-information</v-icon>
        <div class="ma-2" id="paragrafos">
            <div v-for="paragrafo in campo.paragrafos" :key="paragrafo" v-html="mark(paragrafo)">                
            </div>
        </div>
    </v-card>
</template>

<script>
import { marked } from 'marked';

export default {
    name: 'texto',
    props: {
        value: {
            required: false
        },
        campo: {
            type: Object,
            required: true
        }
    },
    methods: {
        mark(paragrafo) {
            return marked.parse(paragrafo);
        },
        addBlankToLinks() {
            document.querySelectorAll('#paragrafos a').forEach(a => {
            a.target = '_blank';            
        });
        }
    },
    mounted() {
        this.addBlankToLinks();
    }
}
</script>