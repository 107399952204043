<template>
    <v-dialog v-model="value" max-width="500">
        <!-- <v-alert dense prominent /*:type="tipoAlerta"*/ class="ma-0 py-4"> -->
        <v-alert
            dense
            prominent
            class="ma-0 py-4"
            color="primary"
            :type="tipoAlerta"
            elevation="2"
        >
            <v-row align="center">
                <v-col class="grow text-h5">
                    <slot></slot>
                </v-col>
                <v-col class="shrink">
                    <v-btn
                        @click="$emit('input', false)"
                        color="white primary--text"
                    >
                        Ok
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </v-dialog>
</template>

<script>
const mapaTipos = {
    sucesso: "success",
    info: "info",
    aviso: "warning",
    erro: "error",
};

export default {
    name: "alerta-modal",
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        tipo: {
            type: String,
            default: "sucesso",
        },
    },
    computed: {
        tipoAlerta: function () {
            return mapaTipos[this.tipo] || "success";
        },
    },
};
</script>
