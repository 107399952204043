export default class MapeadorCaixaChecagem {
    processar({campo, atribuicoes, dadoCampo}) {
        campo.opcoes.forEach(opcao => {
            if(opcao.mapeamento) {
                if(dadoCampo.includes(opcao.valor)) {
                    atribuicoes[opcao.mapeamento] = 'Sim';
                } else {
                    atribuicoes[opcao.mapeamento] = 'Não';
                }
            }            
        });        
    }
}