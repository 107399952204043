class ServicoChamados {
    constructor({ api }) {
        this.api = api;
    }

    obterChamadosAbertos({ idUsuario, offset, limit }) {
        return this.api.obterChamados({
            idUsuario,
            estado: "aberto",
            offset,
            limit,
        });
    }

    obterChamadosFechados({ idUsuario, offset, limit }) {
        return this.api.obterChamados({
            idUsuario,
            estado: "fechado",
            offset,
            limit,
        });
    }

    obterChamado(idTicket) {
        return this.api.obterChamado(idTicket);
    }

    enviarResposta(idTicket, resposta) {
        return this.api.enviarResposta(idTicket, resposta);
    }

    obterNumChamadosNaoVisualizados({ idUsuario, estado }) {
        return this.api.get(
            `/api/visualization/ticket?idUsuario=${idUsuario}&estado=${estado}`
        );
    }

    visualizarArtigo({ idUsuario, ticketId, articleId }) {
        const articleVisualization = {
            ticketId,
            articleId,
            seen: true,
        };
        return this.api.post(
            `/api/visualization/article?idUsuario=${idUsuario}`,
            articleVisualization
        );
    }
}

export default ServicoChamados;
