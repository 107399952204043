<template>
    <v-container class="ufv-container-pages">
        <!-- Loading -->
        <div v-if="loading" class="ufv-menu-loading d-flex justify-center my-8">
            <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
            />
        </div>

        <!-- Conteudo -->
        <v-container v-if="!loading" class="d-md-flex ma-0 pa-0">
            <!-- Painel de Detalhes -->
            <div class="pa-0 ma-3 order-2 mb-6">
                <div
                    class="ufv-container-text font-weight-black text-h6 text-break d-md-none my-3"
                >
                    [{{ chamado.tn }}] {{ chamado.titulo }}
                </div>
                <v-container class="ufv-container-text pa-6">
                    <chamado-prop titulo="Serviço" :valor="chamado.servico" />
                    <chamado-prop
                        titulo="Criado"
                        :valor="formataData(chamado.criado)"
                    />
                    <chamado-prop titulo="Estado" :valor="chamado.estado" />
                    <chamado-prop
                        v-if="chamado.prazoPrimeiraResposta"
                        titulo="Prazo de Primeira Resposta"
                        :valor="formataData(chamado.prazoPrimeiraResposta)"
                    />
                    <chamado-prop
                        v-if="chamado.prazoAtualizacao"
                        titulo="Prazo de Atualização"
                        :valor="formataData(chamado.prazoAtualizacao)"
                    />
                    <chamado-prop
                        v-if="chamado.prazoSolucao"
                        titulo="Prazo de Solução"
                        :valor="formataData(chamado.prazoSolucao)"
                    />
                    <chamado-prop
                        titulo="Prioridade"
                        :valor="chamado.prioridade"
                    />
                    <chamado-prop
                        titulo="Atendente"
                        :valor="chamado.atendente"
                    />
                    <chamado-prop
                        titulo="Responsável"
                        :valor="chamado.responsavel"
                    />
                </v-container>
            </div>
            <!-- Titulo e Artigos -->
            <div class="flex-grow-1 order-1 mostrador">
                <div
                    class="d-none d-md-block ufv-container-text font-weight-black text-h6 my-3"
                >
                    [{{ chamado.tn }}] {{ chamado.titulo }}
                </div>
                <!-- Artigos -->
                <detalhe-artigo
                    v-for="artigo in chamado.artigos"
                    :key="artigo.id"
                    :artigo="artigo"
                    :ticketId="chamado.id"
                    :aberto="mapaDeAberto[artigo.id]"
                    @artigoVisualizado="artigoVisualizado"
                    @abrirFechar="
                        ({ articleId, aberto }) => {
                            atualizarAberto(articleId, aberto);
                        }
                    "
                />
                <!-- Responder  -->
                <div v-if="!respondendo">
                    <v-btn block @click="iniciarRespota"> Responder </v-btn>
                    <!-- <v-alert type="info" outlined class="my-2" v-if="!aberto">
                        Não é possível responder à chamados fechados.
                    </v-alert> -->
                </div>

                <!-- Novo Artigo -->
                <v-slide-y-transition>
                    <edit-artigo
                        v-if="respondendo"
                        @cancelar="cancelarEdicao"
                        @enviar="enviarResposta"
                        v-model="novoArtigo"
                    />
                </v-slide-y-transition>
            </div>
        </v-container>
        <!-- Dialogo de Resposta Enviada com sucesso -->
        <alerta-modal v-model="dialogoRespostaSucesso" tipo="sucesso">
            Resposta enviada com sucesso!
        </alerta-modal>
        <!-- Dialogo de Erro no Envio da Resposta -->
        <alerta-modal v-model="dialogoRespostaErro" tipo="erro">
            {{ msgRespostaErro || "Falha ao enviar resposta!" }}
        </alerta-modal>
    </v-container>
</template>

<style scoped>
.mostrador {
    max-width: 800px;
    overflow-x: scroll;
}
</style>

<script>
import utils from "@/utils/general"; // formataData, limite, etc....
import ChamadoProp from "@/componentes/chamados/ChamadoProp";
import DetalheArtigo from "@/componentes/chamados/DetalheArtigo";
import EditArtigo from "@/componentes/chamados/EditArtigo";
import AlertaModal from "@/componentes/AlertaModal";
import { mapGetters } from "vuex";
import { INTERVALO_ATUALIZACAO_MS } from "@/utils/general";

export default {
    name: "DetalheChamado",
    components: {
        "chamado-prop": ChamadoProp,
        "detalhe-artigo": DetalheArtigo,
        "edit-artigo": EditArtigo,
        "alerta-modal": AlertaModal,
    },
    data() {
        return {
            loading: true,
            respondendo: false,
            chamado: {},
            novoArtigo: {
                corpo: "",
                anexos: [],
            },
            dialogoRespostaSucesso: false,
            dialogoRespostaErro: false,
            msgRespostaErro: "",
            mapaDeAberto: {},
            interval: null,
        };
    },
    computed: {
        ...mapGetters("autenticacao", ["cpf", "email"]),
        aberto: function () {
            return this.chamado.estado !== "fechado";
        },
    },
    mounted() {
        this.buscarChamado();
        this.interval = setInterval(() => {
            this.buscarChamado();
        }, INTERVALO_ATUALIZACAO_MS);
    },
    destroyed() {
        clearInterval(this.interval);
    },
    methods: {
        ...utils,
        buscarChamado() {
            this.loading = true;
            const id = this.$route.params["id"];
            this.$servicoChamados
                .obterChamado(id)
                .then((chamado) => {
                    this.chamado = chamado;
                    this.chamado.artigos.forEach((artigo) => {
                        if (!(artigo.id in this.mapaDeAberto)) {
                            this.atualizarAberto(artigo.id, false);
                        }
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.$router.push({ name: "500" });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        atualizarAberto(articleId, aberto) {
            this.mapaDeAberto = {
                ...this.mapaDeAberto,
                [articleId]: aberto,
            };
        },
        iniciarRespota() {
            this.respondendo = true;
            this.limparNovoArtigo();
        },
        limparNovoArtigo() {
            this.novoArtigo.assunto = `Re: [Ticket#${this.chamado.tn}]`;
            this.novoArtigo.corpo = "";
            this.novoArtigo.anexos = [];
        },
        cancelarEdicao() {
            this.respondendo = false;
            this.limparNovoArtigo();
        },
        enviarResposta() {
            this.loading = true;
            this.novoArtigo.de = this.email;
            this.novoArtigo.criado = utils.formataDataParaApi(Date.now());
            this.novoArtigo.corpo = this.novoArtigo.corpo.replace(
                /\n/g,
                "<br />"
            );
            this.$servicoChamados
                .enviarResposta(this.chamado.id, this.novoArtigo)
                .then((data) => {
                    this.respondendo = false;
                    this.limparNovoArtigo();
                    this.chamado = data;
                    this.loading = false;
                    this.dialogoRespostaSucesso = true;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.msgRespostaErro = err.message;
                    this.dialogoRespostaErro = true;
                });
        },
        artigoVisualizado(id) {
            this.chamado.artigos
                .filter((artigo) => artigo.id === id)
                .forEach((artigo) => {
                    artigo.visualizado = true;
                });
        },
    },
};
</script>
