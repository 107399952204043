import Api from "./Api";

var instance = null;

export function getInstance() {
    if (!instance) {
        instance = new Api();
    }
    return instance;
}
