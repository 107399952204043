export default class ProcessadorConstante {
    calcularValor({operando}) {
        switch(operando.classe) {
            case 'int':
                return parseInt(operando.valor);
            case 'double':
                return parseFloat(operando.valor);
            case 'boolean':
                return (operando.valor === 'true');
            case 'string':
                return operando.valor;
            default:
                throw new Error(`${operando.classe} não é um valor válido para a classe de um operando.`);
        }        
    }
}