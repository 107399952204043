import {
    SALVAR_ATENDIMENTO,
    ATUALIZAR_TELA_ATUAL,
    VOLTAR,
} from "@/vuex/mutations/atendimento";
import Atendimento from "@/entities/Atendimento";
import { handleError } from "@/utils/errorhandler";
import ItemHistorico from "@/entities/ItemHistorico";

export default class ServicoAtendimento {
    constructor({ store, router, mapaProcessadores, api }) {
        this.store = store;
        this.router = router;
        this.mapaProcessadores = mapaProcessadores;
        this.api = api;
    }

    getArvores() {
        return this.api.getArvores();
    }

    iniciarAtendimento(nomeArvore) {
        return this.api
            .getArvore(nomeArvore)
            .then((arvore) => {
                var atendimento = new Atendimento(arvore);
                this.store.commit(
                    "atendimento/" + SALVAR_ATENDIMENTO,
                    atendimento
                );
                this.router.push("/arvore/" + arvore.nome);
            })
            .catch((error) => {
                handleError(this.router, error);
            });
    }

    encerrarAtendimento() {
        this.store.commit("atendimento/" + SALVAR_ATENDIMENTO, null);
    }

    nomeTelaAtual() {
        return this.store.getters["atendimento/nomeTelaAtual"];
    }

    telaAtual() {
        return this.store.getters["atendimento/telaAtual"];
    }

    atribuicoes() {
        return this.store.getters["atendimento/atribuicoes"];
    }

    criarItemHistorico({ dados, atribuicoes }) {
        const copiaDados = Object.assign({}, dados);
        const copiaAtribuicoes = Object.assign({}, atribuicoes);
        return new ItemHistorico({
            nomeTela: this.nomeTelaAtual(),
            dados: copiaDados,
            atribuicoes: copiaAtribuicoes,
        });
    }

    processarTela(dados) {
        const processador = this.mapaProcessadores[this.telaAtual().tipo];
        const atribuicoes = this.atribuicoes();
        const item = this.criarItemHistorico({ dados, atribuicoes });
        const tela = this.telaAtual();
        const proximaTela = processador.processar({ dados, tela, atribuicoes });
        this.store.commit("atendimento/" + ATUALIZAR_TELA_ATUAL, {
            proximaTela,
            item,
            atribuicoes,
        });
    }

    voltar() {
        this.store.commit("atendimento/" + VOLTAR);
    }

    criarChamado() {
        // POST http://localhost:8080/api/ticket
        // {
        //     "usuário": "06786882650",
        //     "assunto": "Apenas um Teste 21h18 - Criado automaticamente",
        //     "fila": "N2::DAU::Apoio ao Desenvolvimento Científico",
        //     "tipo": "Incidente",
        //     "prioridade": "5 Muito Alto",
        //     "serviço": "Processamento de Alto Desempenho",
        //     "sla": "Criação de Conta no Cluster",
        //     "campo01": "Um teste",
        //     "campo02": "17/01/1984",
        //     "campo03": "Outro Teste"
        //   }

        // {
        //     "escalationDestinationIn": "8h 0m",
        //     "service": "Processamento de Alto Desempenho",
        //     "type": "Incidente",
        //     "solutionTimeDestinationDate": "2022-07-21 18:00:00",
        //     "sla": "Criação de Conta no Cluster",
        //     "escalationDestinationDate": "2022-07-21 18:00:00",
        //     "changed": "2022-07-20 21:19:46",
        //     "created": "2022-07-20 21:19:46",
        //     "priority": "5 Muito Alto",
        //     "queue": "N2::DAU::Apoio ao Desenvolvimento Científico",
        //     "state": "new",
        //     "title": "new",
        //     "ticketId": "135069",
        //     "ticketNumber": "2022072010000631",
        //     "createTimeUnix": 1658362786
        //   }

        return this.api
            .criarChamado(this.atribuicoes())
            .then((data) => {
                // var atendimento = new Atendimento(arvore);
                // this.store.commit('atendimento/' + SALVAR_ATENDIMENTO, atendimento);
                return data;
            })
            .catch((error) => {
                throw error;
            });
    }
}
