export default class ProcessadorAnexo {
    processar({dados, tela, atribuicoes}) {
        Object.entries(tela.campos)
        .filter(entry => entry[1].tipo === 'caixaDeAnexo')
        .forEach(entry => {
            if(!atribuicoes.__anexos) {
                atribuicoes.__anexos = [];
            }
            const nome = entry[0];
            
            atribuicoes.__anexos.push(...dados[nome]);
        });
    }
}