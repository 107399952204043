<template>
    <v-btn color="primary" text :disabled="!podeVoltar" @click="voltar()">
        <v-icon>mdi-arrow-left</v-icon>
        {{$t('Voltar')}}
    </v-btn>
</template>

<script>

export default {
    name: "BtnVoltar",
    emits: ['voltar'],
    props: {
        podeVoltar: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        voltar() {
            this.$emit('voltar');            
        }
    }    
};
</script>
