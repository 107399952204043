<template>
    <v-footer color="black">
        <v-container class="d-flex ufv-footer">
            <div class="ufv-footer-content">
                <p class="ufv-footer-content-title">Viçosa</p>
                <div>
                    <p class="ufv-footer-content-text">
                        Av. Peter Henry Rolfs, s/n, Campus Universitário
                        36570-900 Viçosa/MG
                    </p>
                </div>
            </div>
            <div class="ufv-footer-content">
                <p class="ufv-footer-content-title">Florestal</p>
                <div>
                    <p class="ufv-footer-content-text">
                        Rodovia LMG 818, km 6 35690-000 Florestal/MG
                    </p>
                </div>
            </div>
            <div class="ufv-footer-content">
                <p class="ufv-footer-content-title">Rio paranaíba</p>
                <div>
                    <p class="ufv-footer-content-text">
                        Km 7 – Zona Rural, MG-230, Rodoviário 38810-000 Rio
                        Paranaíba/MG
                    </p>
                </div>
            </div>
        </v-container>
    </v-footer>
</template>
<script>
export default {
    name: "Footer",
};
</script>

<style scoped>
.ufv-footer {
    justify-content: center;
}
.ufv-footer-content {
    margin-right: 5%;
    margin-left: 5%;
}
.ufv-footer-content-title {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 15px;
    color: white;
    font-weight: bold;
}
.ufv-footer-content-text{
    font-family: "lucida_sansdemibold", sans-serif;
    font-size: 12px;
    color:#a9afb5;
}
</style>
