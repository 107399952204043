<template>
    <div class="mb-6">
        <!-- <ul>
            <li>Processar: </li>
            <li>---- > Fluxo</li>
            <li>---- > Atribuição</li>
        </ul> -->
        <!-- CABEÇALHO -->
        <h3>{{ campo.rotulo }}</h3>
        <div>
            {{ campo.ajuda }}
            <span v-if="msgMinimoMaximo">({{ msgMinimoMaximo }})</span>
        </div>
        <!-- TABELA -->
        <v-simple-table v-if="deveExibirTabela" class="my-2" dense>
            <thead>
                <tr>
                    <th v-for="(valor, nome) in campo.campos" :key="nome">
                        {{ nome }}
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(dado, index) in value" :key="index">
                    <td v-for="(valor, nome) in campo.campos" :key="nome">
                        <span v-if="dado[nome]">{{ dado[nome] }}</span>
                        <span v-else></span>
                    </td>
                    <td>
                        <v-btn small icon @click="abrirEdicao(index)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </td>
                    <td>
                        <v-btn small icon @click="removerItem(index)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <!-- INSERIR ITEM -->
        <v-btn
            block
            @click="abrirEdicao(-1)"
            v-if="!editando"
            class="my-2"
            :disabled="!deveHabilitarInsercao"
        >
            Clique Aqui para inserir um item
            <v-icon right>mdi-plus-circle</v-icon>
        </v-btn>
        <!-- PAINEL DE INSERÇÃO  -->
        <painel-insercao
            v-if="editando"
            class="my-6"
            :campos="campo.campos"
            :valores="linha"
            @cancelar="cancelarEdicao"
            @salvar="salvarItem"
        />
        <!-- DIALOGO DE REMOCAO DE ITEM -->
        <v-dialog v-model="removendo" persistent max-width="500">
            <v-card>
                <v-card-title class="primary white--text"
                    >Exluir Item</v-card-title
                >
                <v-card-text class="text-h6 mt-6"
                    >Deseja realmente excliur este item?</v-card-text
                >
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outilined
                        text
                        color="primary"
                        @click="confirmarRemocao()"
                        >Ok</v-btn
                    >
                    <v-btn outilined text @click="cancelarRemocao()"
                        >Cancelar</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import PainelInsercao from "./tabela/PainelInsercao";

export default {
    name: "tabela",
    components: {
        "painel-insercao": PainelInsercao,
    },
    props: {
        campo: {
            type: Object,
            required: true,
        },
        value: {
            type: Array,
        },
    },
    data() {
        return {
            editando: false,
            removendo: false,
            indice: -1,
            linha: {},
        };
    },
    computed: {
        deveExibirTabela: function () {
            return (
                this.campo.campos &&
                Object.keys(this.campo.campos).length > 0 &&
                this.value &&
                this.value.length > 0
            );
        },
        deveHabilitarInsercao: function () {
            return (
                this.campo.maximo &&
                this.value &&
                this.value.length < this.campo.maximo
            );
        },
        msgMinimoMaximo: function () {
            var msg = "";
            if (this.campo.minimo) {
                var min = `${this.campo.minimo} item`;
                if (this.campo.minimo > 1) {
                    min = `${this.campo.minimo} itens`;
                }
                msg += `mínimo de ${min}`;
                if (this.campo.maximo) {
                    msg += " e ";
                }
            }
            if (this.campo.maximo) {
                var max = `${this.campo.maximo} item`;
                if (this.campo.maximo > 1) {
                    max = `${this.campo.maximo} itens`;
                }
                msg += `máximo de ${max}`;
            }
            return msg;
        },
    },
    mounted() {
        if (!this.value) {
            this.$emit("input", []);
        }
    },
    methods: {
        resetarLinha(item) {
            if (item == null) {
                this.linha = {};
                Object.keys(this.campo.campos).forEach((el) => {
                    let campo = this.campo.campos[el];
                    if (campo.valor != undefined) {
                        this.linha[el] = campo.valor;
                    } else {
                        this.linha[el] = null;
                    }
                });
            } else {
                this.linha = { ...item };
            }
        },
        abrirEdicao(index) {
            this.indice = index;
            if (index == -1) {
                this.resetarLinha(null);
            } else {
                this.resetarLinha(this.value[index]);
            }
            this.editando = true;
        },
        cancelarEdicao() {
            this.editando = false;
        },
        salvarItem(valores) {
            this.editando = false;
            if (this.indice == -1) {
                this.value.push(valores);
            } else {
                this.value[this.indice] = { ...valores };
            }
            this.$emit("input", this.value);
        },
        removerItem(index) {
            this.removendo = true;
            this.indice = index;
        },
        confirmarRemocao() {
            this.value.splice(this.indice, 1);
            this.$emit("input", this.value);
            this.removendo = false;
        },
        cancelarRemocao() {
            this.removendo = false;
        },
    },
};
</script>
