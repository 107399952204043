export default class ProcessadorVariavel {

    separadaPorPonto(nome) {
        let index = nome.search(/\./);
        return index > 0 && index < nome.length - 1;
    }

    calcularSubvalor({operando, dados}) {
        let [variavel, subvariavel] = operando.campo.split('.');
        return dados[variavel].includes(subvariavel);
    }

    calcularValor({operando, dados}) {
        if(this.separadaPorPonto(operando.campo)) {
            return this.calcularSubvalor({operando, dados});
        } else {
            return dados[operando.campo];
        }        
    }
}