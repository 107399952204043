import Vue from 'vue'
import VueI18n from 'vue-i18n';

import pt_BR from './pt_BR'
import en_US from './en_US'
/**
 * Arquivo configuração do plugin de tradução de textos
 * Plugin: vue-i18n
 * @Documentação https://kazupon.github.io/vue-i18n/
 * @Documentação https://www.npmjs.com/package/vue-i18n
 */
Vue.use(VueI18n);
/**
 * Traduções implementadas
 */
const messages = {
    pt_BR: { ...pt_BR },
    en_us: { ...en_US },       
};
/**
 * Idioma padrão
 */
const i18n = new VueI18n({
    locale: 'pt_BR',
    messages
});
export default i18n;