/**
 * Arquivo de tradução 
 * Idioma: Português
 */
const message = {
    //TEMPORARIO
    Arvore001: 'Arvore001',
    Arvore02: 'Arvore02',
    //A
    Acessar: 'Acessar',
    Anterior: 'Anterior',
    Arvore: 'Arvore',
    Atendimento: 'Atendimento',
    Avancar: 'Avançar',
    //C
    Cancelar: 'Cancelar',
    CriarChamado: 'Criar chamado',
    CriarChamadoTexto: 'Seu problema será registrado em nosso sistema e uma requisição de atendimento será aberta em seu nome. Deseja continuar ?',
    Criar: 'Criar',
    //E
    Erro500: "Estamos com problemas em nossos servidores. Por favor, tente mais tarde.",
    ErroAoProcessarRequisicao: 'Erro ao processar requisição',
    ErroAoProcessarRequisicaoTexto: 'Desculpe, nâo conseguimos processar sua requisição',
    EscolhaUmaOpcao:'Escolha uma opção',
    EscolhaUmaOpcaoParaIniciar: 'Escolha uma opção para iniciar',
    //H
    Home: 'Início',
    //I
    IniciarAtendimento: 'Iniciar atendimento',
    //L
    Login: 'Entrar',
    Logout: 'Sair',
    //M
    MensagemBoasVindas: 'Bem vindo ao sistema',
    //N
    NaoOProblemaContinua: 'Não, o problema continua',
    NomeDoSistema: 'Árvore de decisão',
    //P
    PaginaNaoEncontrada: 'Página não encontrada',
    PaginaNaoEncontradaTexto: 'A página que você procura não existe',
    Proximo: 'Próximo',
    //S
    SeuProblemaFoiResolvido: 'Seu problema foi resolvido ?',
    Sim: 'Sim',
    //T
    Ticket: 'Ticket',
    Tutorial: 'Tutorial',
    //V
    VoceEstaEm: 'Você está em',
    Voltar: 'Voltar'
}
export default message;