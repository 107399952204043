import jwt_decode from "jwt-decode";
import { LOGIN, LOGOUT, UPDATE_TICKET } from "../actions/autenticacao";

const SET_TOKEN = "setToken";
const CLEAR_TOKEN = "clearToken";

const state = {
    token: null,
    decodedToken: null,
};

const isExpired = (state) => {
    if (state.token) {
        const now = new Date(Date.now());
        const expiresAt = new Date(state.decodedToken.exp * 1000);
        return now > expiresAt;
    }
    return true;
};

const isAuthenticated = (state) => {
    if (state.token) {
        return !isExpired(state);
    }
    return false;
};

const getters = {
    isExpired,
    isAuthenticated,
    username: (state) => {
        if (state.decodedToken) {
            return state.decodedToken.nome || state.decodedToken.name;
        }
    },
    token: (state) => state.token,
    cpf: (state) => state.decodedToken.cpf,
    email: (state) => state.decodedToken.email,
    clientName: (state) => state.decodedToken.clientName,
};

const actions = {
    [LOGIN]: () => {
        let service = encodeURIComponent(window.location.href);
        let loginUrl = process.env.VUE_APP_LOGIN_URL;
        let url = loginUrl + "?service=" + service;
        window.location.href = url;
    },
    [LOGOUT]: ({ commit }) => {
        commit(CLEAR_TOKEN);
        let url = process.env.VUE_APP_LOGOUT_URL;
        window.location.href = url;
    },
    [UPDATE_TICKET]: ({ commit, getters }, ticket) => {
        if (getters.token !== ticket) {
            commit(SET_TOKEN, ticket);
        }
    },
};

const mutations = {
    [SET_TOKEN]: (state, token) => {
        state.token = token;
        state.decodedToken = jwt_decode(token);
    },
    [CLEAR_TOKEN]: (state) => {
        (state.token = null), (state.decodedToken = null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
