import ViewTelaOpcoes from "@/views/atendimento/arvore/ViewTelaOpcoes";
import ViewTelaTutorial from "@/views/atendimento/arvore/ViewTelaTutorial";
import ViewTelaFormulario from "@/views/atendimento/arvore/ViewTelaFormulario";
import ViewTelaFinal from "@/views/atendimento/arvore/ViewTelaFinal.vue";

export default class ConfigMapaTelas {
    constructor({fabricaProcessadores}) {
        this.fabricaProcessadores = fabricaProcessadores;
    }

    criarOpcoes() {
        const { processadorTelaOpcao, processadorTelaTutorial, processadorTelaFormulario }  = this.fabricaProcessadores.criarProcessadores();
        
        const opcoes = [
            {
                tipo: 'opcoes',
                nome: 'tela-opcoes',
                componente: ViewTelaOpcoes,
                processador: processadorTelaOpcao
            },
            {
                tipo: 'tutorial',
                nome: 'tela-tutorial',
                componente: ViewTelaTutorial,
                processador: processadorTelaTutorial
            },
            {
                tipo: 'formulario',
                nome: 'tela-formulario',
                componente: ViewTelaFormulario,
                processador: processadorTelaFormulario
            },
            {
                tipo: 'final',
                nome: 'tela-final',
                componente: ViewTelaFinal,
                processador: null
            }
        ];
        return opcoes;
    }

    configurar(app) {
        const mapa = {};
        const mapaProcessadores = {};
        const opcoes = this.criarOpcoes();
        opcoes.forEach(({tipo, nome, componente, processador}) => {
            app.component(nome, componente);
            mapa[tipo] = nome;
            mapaProcessadores[tipo] = processador;
        });
        app.prototype.$mapaTelas = (tipo) => {
            return mapa[tipo];
        }

        return mapaProcessadores;
    }
}