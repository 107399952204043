import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
/**
 * Arquivo configuração do plugin de front-end
 * Plugin: Vuetify
 * @Documentação https://vuetifyjs.com/en/
 */
Vue.use(Vuetify);
export default new Vuetify({
    theme: {
        themes: {
          light: {
            /* Configura cor primaria do plugin - cor baseada no padrão de sistemas da UFV */
            primary: "#682A2C",
          },
        },
      },
});
