export default class ProcessadorTelaTutorial {
    constructor({processadorAtribuicao}) {
        this.processadorAtribuicao = processadorAtribuicao;
    }

    processarAtribuicoes({dados, tela , atribuicoes}) {
        if(tela.atribuicoes === undefined || tela.atribuicoes === null) {
            return
        }
        tela.atribuicoes.forEach(atribuicao => {
            this.processadorAtribuicao.processar({atribuicao, dados, atribuicoes});
        });
    }

    processar({dados, tela , atribuicoes}) {
        this.processarAtribuicoes({dados, tela, atribuicoes});
        if(dados.escolha === 'sim') {
            return tela.telaSim;
        } else {
            return tela.telaNao;
        }
    }
}
