import Verificador from "@/verificadores/Verificador";
import VerificadorCaixaAnexo from "@/verificadores/VerificadorCaixaAnexo";
import VerificadorTabela from "@/verificadores/VerificadorTabela";

export default class ConfigVerificador {
    configurar(app) {
        const vericador = new Verificador();
        vericador.registrar('caixaDeAnexo', new VerificadorCaixaAnexo());
        vericador.registrar('tabela', new VerificadorTabela());

        app.prototype.$verificador = vericador;
    }
}