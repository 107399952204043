<template>
    <v-container fluid class="ma-0 pa-0">
        <v-textarea
            :label="campo.rotulo"
            :value="value"
            @input="updateValue"
            :hint="campo.ajuda"
            clearable
            autoGrow
            filled
            :rules="validadores"
        >
        </v-textarea>
    </v-container>
</template>

<script>
import { montarValidadores } from "@/utils/ajudaComponentes";

export default {
    name: "memorando",
    props: {
        value: {
            type: String,
        },
        campo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            validadores: [],
        };
    },
    mounted() {
        montarValidadores(this);
    },
    methods: {
        updateValue(event) {
            this.$emit("input", event);
        },
    },
};
</script>

<style></style>
