export default class VerificadorTabela {
    validar(campo, valor) {
        const erros = [];
        if(campo.minimo && valor.length < campo.minimo) {
            erros.push(`É necessário um mínimo de ${campo.minimo} item(ns)`);
        }

        if(campo.maximo && valor.length > campo.maximo) {
            erros.push(`Só é permitido um máximo de ${campo.maximo} item(ns)`);
        }
        return erros;
    }
}