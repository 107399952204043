import CaixaDeTexto from "@/componentes/formulario/CaixaDeTexto";
import Memorando from "@/componentes/formulario/Memorando";
import CaixaDeSelecao from "@/componentes/formulario/CaixaDeSelecao";
import CaixaDeChecagem from "@/componentes/formulario/CaixaDeChecagem";
import Texto from "@/componentes/formulario/Texto";
import CaixaDeAnexo from "@/componentes/formulario/CaixaDeAnexo";
import Tabela from "@/componentes/formulario/Tabela";

export default class ConfigMapaCampos {
    /**
     * Cria e congigura um mapa de campos e atribui à variavel $mapaCampos durante a instalacao do plugin
     * 
     * Como a View de Formulario utiliza componente dinâmicos, o mapa de campos é utlizado para se saber qual
     * componente deverá ser renderizado. Portanto, sempre que um novo compoente for inserido ou removido,
     * a configuração deverá ser feita aqui.
     * @param {*} app  - parametro fornecido na instalação do plugin
     */
    configurar(app) {
        const mapaCampos = [
            {
                tipo: 'caixaDeTexto',
                nome: 'caixa-de-texto',
                componente: CaixaDeTexto
            },
            {
                tipo: 'memorando',
                nome: 'memorando',
                componente: Memorando
            },
            {
                tipo: 'caixaDeSelecao',
                nome: 'caixa-de-selecao',
                componente: CaixaDeSelecao
            },
            {
                tipo: 'caixaDeChecagem',
                nome: 'caixa-de-checagem',
                componente: CaixaDeChecagem
            },
            {
                tipo: 'texto',
                nome: 'texto',
                componente: Texto
            },
            {
                tipo: 'caixaDeAnexo',
                nome: 'caixa-de-anexo',
                componente: CaixaDeAnexo
            },
            {
                tipo: 'tabela',
                nome: 'tabela',
                componente: Tabela
            }
        ];

        const mapa = {};
        mapaCampos.forEach(({tipo, nome, componente}) => {
            app.component(nome, componente);
            mapa[tipo] = nome;
        })

        app.prototype.$mapaCampos = (tipo) => {
            return mapa[tipo];
        };        
    }
}