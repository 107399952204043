import ArvoreException from "../entities/exception/ArvoreException";
/**
 * Classe Utils
 * Classe auxiliar de utilidades
 */
class Utils {
    /**
     * Mapeia um tipo de erro/exceção em uma rota
     * @param ERROR:String 
     * @returns 
     */
    static errorToRoute(ERROR) {
        switch (ERROR) {
            case ArvoreException.ARVORE_NAO_ENCONTRADA_EXCEPTION:
                return "/404";
            case ArvoreException.PARSE_ARVORE_EXCEPTION:
                return "/400";
            default:
                return "/404";
        }
    }
    /**
     * Transforma uma string contendo um token em um objeto com os dados do token
     * @param tokenString:String 
     * @returns Object
     */
    static decompoeToken(tokenString) {
        try {
            var base64Url = tokenString.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        } catch (error) {
            console.log("Não foi possível decompor token");
            return null;
        }
    }
}
export default Utils;