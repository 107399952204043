import Vue from "vue";
import VueRouter from "vue-router";

import Arvore from "../views/atendimento/arvore/ViewArvore.vue";
import Atendimento from "../views/atendimento/Atendimento.vue";
import ChamadosAbertos from "@/views/chamados/ChamadosAbertos";
import ChamadosFechados from "@/views/chamados/ChamadosFechados";
import DetalheChamado from "@/views/chamados/DetalheChamado";
import Home from "../views/Home.vue";
import PageNotFound from "../views/error/404.vue";
import Page400 from "../views/error/400.vue";
import Page500 from "@/views/error/500.vue";

import store from "@/vuex/store";
import { UPDATE_TICKET, LOGIN } from "@/vuex/actions/autenticacao";

Vue.use(VueRouter);

const routes = [
    {
        path: "/400",
        name: "400",
        component: Page400,
    },
    {
        path: "/500",
        name: "500",
        component: Page500,
    },
    {
        path: "/",
        alias: "/home",
        name: "Home",
        component: Home,
    },
    {
        path: "/atendimento",
        name: "Atendimento",
        component: Atendimento,
        meta: {
            restricted: true,
        },
    },
    {
        path: "/chamados/abertos",
        name: "ChamadosAbertos",
        component: ChamadosAbertos,
        meta: {
            restricted: true,
        },
    },
    {
        path: "/chamados/fechados",
        name: "ChamadosFechados",
        component: ChamadosFechados,
        meta: {
            restricted: true,
        },
    },
    {
        path: "/chamados/detalhe/:id",
        name: "DetalheChamado",
        component: DetalheChamado,
        meta: {
            restricted: true,
        },
    },
    {
        path: "/arvore/:nome",
        name: "Arvore",
        component: Arvore,
        meta: {
            restricted: true,
        },
    },
    {
        path: "/404",
        name: "NotFound",
        component: PageNotFound,
    },
    {
        path: "*",
        name: "404",
        component: PageNotFound,
    },
];
const router = new VueRouter({
    mode: "history",
    routes,
});

const removerTicketDaURL = (to) => {
    store.dispatch("autenticacao/" + UPDATE_TICKET, to.query.ticket);
    delete to.query.ticket;
    delete to.query.redirect;
    router.replace(to);
};

const paginaRestrita = (to) => {
    return to.meta.restricted && !store.getters["autenticacao/isAuthenticated"];
};

const redirecionarParaLogin = (next) => {
    store.dispatch("autenticacao/" + LOGIN);
    next(false);
};

const checkTicket = (to, from, next) => {
    if ("ticket" in to.query) {
        removerTicketDaURL(to);
    }

    if (paginaRestrita(to)) {
        redirecionarParaLogin(next);
    } else {
        next();
    }
};

router.beforeEach(checkTicket);

export default router;
