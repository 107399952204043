import ValidadorSimples from "@/processadores/validadores/ValidadorSimples";
import ValidadorVazio from "@/processadores/validadores/ValidadorVazio";
import ValidadorMascara from "@/processadores/validadores/ValidadorMascara";

export default class ConfigMapaValidadores {
    configurar(app) {
        const validadorSimples = new ValidadorSimples();
        const validadorVazio = new ValidadorVazio();
        const validadorMascara = new ValidadorMascara();

        const mapa = {
            'simples': validadorSimples,
            'naoVazio': validadorVazio,
            'mascara': validadorMascara
        };

        app.prototype.$mapaValidadores = (tipo) => {
            return mapa[tipo];
        };
    }
}