<template>
    <v-container class="d-flex mx-0 my-2 grey--text text--darken-2">
        <!-- Icone arquivo -->
        <div><v-icon class="text-h3 gray--text">mdi-file</v-icon></div>
        <!-- Parte central -->
        <div class="mx-1 flex-grow-1 flex-shrink-0 d-flex flex-column justify-space-around">
            <!-- Info arquivo -->
            <div class="d-flex justify-space-between">
                <div>{{ nameDisplay }}</div>
                <div>{{ progress }}%</div>
            </div>
            <!-- Progresso -->
            <div class="grey lighten-3 flex-grow-1 rounded-pill d-flex" style="max-height: 8px;">
                <div class="grey darken-2 rounded-pill" ref="progressbar"></div>
            </div>
        </div>
        <!-- Icone Lixeira -->
        <div>
            <v-icon class="text-h3 gray--text" @click="$emit('itemCancelado', item)">mdi-delete</v-icon>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'item-loader',
    props: {
        item: {
            required: true,
            type: File
        },
        pausado: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            progress: 0
        }
    },
    emits: ['itemCarregado', 'itemCancelado', 'erro'],
    methods: {
        toMega: function(value) {
            return (value * 0.000001).toFixed(2) + "MB"; 
        },
        truncate: function(text) {
            if(text.length >= 40) {
                return text.slice(0, 38) + "...";
            } else {
                return text;
            }
        },
        startLoading: async function() {
            const reader = new FileReader();
            reader.addEventListener('load', event => {
                this.item.content = event.target.result;
                this.$emit('itemCarregado', this.item);
            });
            reader.addEventListener('progress', event => {
                if(event.loaded && event.total) {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    this.progress = percent;
                }
            });
            reader.addEventListener('error', event => {
                this.$emit('erro', event);
            });
            reader.readAsDataURL(this.item);
        }
    },
    computed: {
        nameDisplay: function() {
            return this.truncate(this.item.name + " - " + this.toMega(this.item.size));
        }
    },
    watch: {
        pausado: function(val) {
            if(!val) {
                this.startLoading();
            }
        },
        progress: function(val) {
            this.$refs.progressbar.style.width = `${val}%`;
        }
    },
    mounted() {
        this.$refs.progressbar.style.width = "0%";
        if(!this.pausado) {
            this.startLoading();
        }
    }
}
</script>
