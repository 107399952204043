import Vue from 'vue'
import App from './App.vue'

import i18n from './plugins/idiomas/index'
import router from './router'
import store from './vuex/store'
import vuetify from './plugins/vuetify'
import arvoreDeDecisao from './plugins/arvoreDeDecisao';
import VueTheMask from 'vue-the-mask';
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(arvoreDeDecisao, {store, router});

Vue.filter('capitalize', function (value) {
    if (!value) {
        return '';
    }
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.use(VueTheMask);

new Vue({
    i18n,
    router,
    store,
    vuetify,    
    render: h => h(App)
}).$mount('#app')