<template>
    <div>
        <div class="ufv-btn-voltar">
            <v-row>
                <ViewBotaoVoltar :podeVoltar="podeVoltar" @voltar="voltar" />
                <v-spacer />
            </v-row>
        </div>
        <v-card>
            <div class="ufv-container-text">
                <div class="text-h4">{{ $t("Tutorial") }}</div>
            </div>
            <v-container class="ufv-container-tutorial">
                <iframe v-if="tela.fonte === 'youtube'"
                    width="560" height="315" :src="tela.url"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>                
            </v-container>
            <v-container class="ufv-container-tutorial">
                <ViewOtrsFaq v-if="tela.fonte === 'otrs'" :faqUrl="tela.url" />
            </v-container>
            <div v-if="tela.fonte == 'link'" class="ufv-tutorial-link">
                Acesse a página do <b><a :href="tela.url" target="_blank">tutorial</a></b> e
                siga os procedimentos
            </div>
            <div class="ufv-container-text">
                <div class="ufv-tutorial-footer">
                    <div class="text-h6">
                        {{ $t("SeuProblemaFoiResolvido") }}
                    </div>
                    <v-btn @click="problemaResolvido()">{{ $t("Sim") }}</v-btn>
                    <v-btn @click="problemaContinua()">{{
                        $t("NaoOProblemaContinua")
                    }}</v-btn>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import ViewBotaoVoltar from "@/views/atendimento/arvore/elementos/ViewBotaoVoltar";
import ViewOtrsFaq from "@/views/atendimento/arvore/tutorial/ViewOtrsFaq";

export default {
    name: "telaTutorial",
    emits: ['processar', 'voltar'],
    props: {
        tela: {
            type: Object,
            required: true,
        },
        podeVoltar: {
            type: Boolean,
            required: true
        }
    },
    components: {
        ViewBotaoVoltar,
        ViewOtrsFaq
    },
    methods: {
        problemaResolvido() {
            this.$emit('processar', {escolha: 'sim'});
        },
        problemaContinua() {
            this.$emit('processar', {escolha: 'nao'});
        },
        voltar() {
            this.$emit('voltar');
        }
    }
};
</script>

<style scoped>
.ufv-container-tutorial {
    margin-top: 1%;
    text-align: center;
}
.ufv-tutorial-link{
    padding: 1%;
    margin-bottom: 15px;
    text-align: center;
}
.ufv-tutorial-footer {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;
}
</style>