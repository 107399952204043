const MSG_INT = "O valor deve ser um número inteiro";
const MSG_DOUBLE = "O valor deve ser um número";
const MSG_STRING = "O valor deve ser preenchido";

export default class ValidadorSimples {
    
    msgInt() {
        return MSG_INT;
    }

    msgDouble() {
        return MSG_DOUBLE;
    }

    msgString() {
        return MSG_STRING;
    }

    validarInt(valor) {
        let num = Number(valor);
        return Number.isInteger(num) || MSG_INT;
    }

    validarDouble(valor) {
        let num = Number(valor);
        return !Number.isNaN(num) || MSG_DOUBLE;
    }

    aplicar() {}

    validar({validador, valor}) {
        switch(validador.formato) {
            case "int":
                return this.validarInt(valor);
            case "double":
                return this.validarDouble(valor);
            case "string":
                if(valor === undefined || valor === null) {
                    return MSG_STRING;
                }
                return true;
        }        
    }
}