class ArvoreException extends Error {

    static ARVORE_SEM_NOME = "A arvore deve possuir um nome";
    static ARVORE_NAO_ENCONTRADA_EXCEPTION = "Árvore não encontrada";
    static PARSE_ARVORE_EXCEPTION = "Não foi possível converter a arvore";

    constructor(mensagem) {
        super(mensagem);
    }
}
export default ArvoreException