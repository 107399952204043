<template>
    <v-card class="rounded-0 mb-3" outlined>
        <v-card-title class="pa-0 ma-0 text-h6 grey lighten-4 d-flex blue">
            <v-icon class="text-h6 px-2">mdi-note-edit-outline</v-icon>
            <div>Escreva sua resposta</div>
        </v-card-title>
        <v-card-text class="pa-4">
            <v-text-field
                label="Assunto"
                v-model="value.assunto"
                class="edit-assunto"
            />
            <v-textarea
                v-model="value.corpo"
                auto-grow
                clearable
                label="Escreva sua resposta aqui..."
                class="edit-artigo"
                ref="campoResposta"
            />
            <v-file-input
                label="Anexos"
                dense
                multiple
                small-chips
                @change="novosAnexos"
                v-model="arquivoCorrente"
            ></v-file-input>
            <div>
                <v-chip
                    v-for="arquivo in value.anexos"
                    :key="arquivo.filename"
                    class="ma-1"
                    close
                    @click:close="removerAnexo(arquivo.filename)"
                >
                    {{ arquivo.filename }}
                </v-chip>
            </div>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn
                text
                @click="enviar"
                color="primary"
                :disabled="!camposValidos"
                >Enviar</v-btn
            >
            <v-btn text @click="cancelar">Cancelar</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
const convertFilePromise = (file) => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.addEventListener("load", (e) => {
            const preText = `data:${file.type};base64,`;
            const content = e.target.result.slice(preText.length);
            res({
                filename: file.name,
                contenttype: file.type,
                content: content,
            });
        });
        reader.addEventListener("error", () => {
            rej("Falha ao converter arquivo");
        });
        reader.readAsDataURL(file);
    });
};

export default {
    name: "edit-artigo",
    props: ["value"],
    data() {
        return {
            arquivoCorrente: [],
        };
    },
    emits: ["cancelar", "enviar"],
    methods: {
        novosAnexos(files) {
            const fileReadPromises = files.map((file) => {
                return convertFilePromise(file);
            });

            Promise.all(fileReadPromises)
                .then((convertedFiles) => {
                    this.$emit("input", {
                        assunto: this.value.assunto,
                        corpo: this.value.corpo,
                        anexos: [...this.value.anexos, ...convertedFiles],
                    });
                })
                .finally(() => {
                    this.arquivoCorrente = [];
                });
        },
        removerAnexo(filename) {
            const novosAnexos = (this.value.anexos = this.value.anexos.filter(
                (arquivo) => arquivo.filename !== filename
            ));
            this.$emit("input", {
                assunto: this.value.assunto,
                corpo: this.value.corpo,
                anexos: novosAnexos,
            });
        },
        cancelar() {
            this.$emit("cancelar");
        },
        enviar() {
            this.$emit("enviar");
        },
    },
    computed: {
        camposValidos: function () {
            return this.value.assunto && this.value.corpo;
        },
    },
    mounted() {
        this.$refs.campoResposta.focus();
    },
};
</script>
