import { getInstance as apiGetInstance } from "../api/ApiFactory";
import ServicoChamados from "./ServicoChamados";

var servicoChamadosInstance = null;

export function getServicoChamadosInstance() {
    if (!servicoChamadosInstance) {
        const api = apiGetInstance();
        servicoChamadosInstance = new ServicoChamados({ api });
    }
    return servicoChamadosInstance;
}
