<template>
    <div>
        <component :is="$mapaTelas(tela.tipo)" v-if="!remontar"
            :tela="tela" :dados="dados" :podeVoltar="podeVoltar" @voltar="voltar" @processar="processar"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "tela",
    props: {
        tela: {
            type: Object,
            required: true,
        },
        dados: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            remontar: false,
        }
    },    
    computed: {
        ...mapGetters('atendimento', {
            podeVoltar: 'podeVoltar'
        })
    },
    methods: {
        forcarRemontagem() {
            this.remontar = true;
            this.$nextTick(() => {
                this.remontar = false;
            });
        },
        processar(dados) {
            this.$servicoAtendimento.processarTela(dados);
            this.forcarRemontagem();
        },
        voltar() {
            this.$servicoAtendimento.voltar();            
            this.forcarRemontagem();
        }
    },
};
</script>
