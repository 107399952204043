import Historico from "@/entities/Historico";

/**
 * Classe responsável por armazenar as iformaçções sobre o atenidmentos.
 * @author Diego Fialho Rodrigues
 */
export default class Atendimento {
    constructor(arvore) {
        this.arvore = arvore;
        this.historico = new Historico();
        this.telaAtual = arvore.inicial;
        this.atribuicoes = {};
        this.dados = {};
    }
}