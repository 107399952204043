<template>
  <div v-if="mascara">
    <v-text-field :label="campo.rotulo" :hint="campo.ajuda" :rules="validadores" v-mask="mascara" filled :value="value" @input="updateValue" />      
  </div>
  <div v-else>
    <v-text-field :label="campo.rotulo" :hint="campo.ajuda" :rules="validadores" filled :value="value" @input="updateValue" />      
  </div>  
</template>

<script>
import { montarValidadores } from '@/utils/ajudaComponentes';

export default {
    name: 'caixa-de-texto',
    props: {
      value: {
        type: String        
      },
      campo: {
        type: Object,
        required: true
      }      
    },
    data() {
      return {
        validadores: [],
        mascara: null,
      }
    },
    mounted() {
      montarValidadores(this);      
    },
    methods: {
      updateValue(event) {
        this.$emit("input", event);
      }
    }
}
</script>

<style>

</style>