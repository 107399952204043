<template>
    <v-app>
        <Appbar />
        <v-main class="ufv-main">
            <router-view />
        </v-main>
        <Footer />
    </v-app>
</template>

<script>
import Appbar from "./views/app/Appbar.vue";
import Footer from "./views/app/Footer.vue";

export default {
    name: "App",

    components: {
        Appbar,
        Footer,
    },
};
</script>

<style>
.ufv-main {
    height: 100%;
}
.ufv-container-home {
    text-align: center;
}
.ufv-container-pages {
    text-align: start;
    word-break: break-all;
}
@media screen and (max-width: 400px) {
    .ufv-container-pages .text-h1 {
        font-size: 55px !important;
    }
    .ufv-container-pages .text-h5 {
        font-size: 20px !important;
    }
}
.ufv-container-text {
    padding: 30px;
    background-color: #eee;
}
.ufv-container-btn {
    margin-top: 20px;
}
.ufv-btn-voltar {
    margin: 20px;
}
</style>
