<template>
    <v-container class="ufv-container-pages">
        <ViewTela v-if="tela != null" :tela="tela" :dados="dados" />
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewTela from "@/views/atendimento/arvore/ViewTela";

export default {
    name: "arvore",

    components: {
        ViewTela,
    },
    computed: {
        ...mapGetters('atendimento', {
            tela: 'telaAtual',
            dados: 'dados'
        })
    },
};
</script>
<style scoped></style>