<template>
    <v-row>
        <v-col class="text-right pa-1 text-break font-weight-medium">{{ titulo }}:</v-col>
        <v-col class="pa-1 text-break">{{ valor }}</v-col>
    </v-row> 
</template>

<script>
export default {
    name: 'chamado-prop',
    props: ['titulo', 'valor']
};
</script>
