const MSG = "Preenchimento obrigatório"

export default class ValidadorVazio {
    msg() {
        return MSG;
    }
    
    aplicar() {}

    validar({valor}) {
        return !!valor || this.msg();
    }
}