export default class ProcessadorFluxos {
    constructor({mapaCondicoes}) {
        this.mapaCondicoes = mapaCondicoes;
    }

    avaliarCondicao({condicao, dados}) {
        if(condicao === undefined || condicao === null) {
            return true;
        }
        let tipoCond = condicao.tipo;
        let processadorCondicao = this.mapaCondicoes[tipoCond];
        let avaliacao = processadorCondicao.avaliar({dados, condicao});
        return avaliacao;
    }

    processar({fluxos, dados}) {
        for(const {tela, condicao} of fluxos) {
            if(this.avaliarCondicao({condicao, dados})) {
                return tela;
            }            
        }
    }
}