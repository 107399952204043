/*
Verifica se os campos de um formulario são validos
*/
export default class Verificador {
    constructor() {
        this.verificadores = {};
    }

    registrar(tipo, verificador) {
        this.verificadores[tipo] = verificador;
    }

    validarCampo(campo, valor) {        
        const verificador = this.verificadores[campo.tipo];
        if(!verificador) {
            return null;
        }

        return verificador.validar(campo, valor);        
    }

    validar(campos, valores) {
        const erros = {};
        Object.keys(valores).forEach(key => {            
            const erro = this.validarCampo(campos[key], valores[key]);
            if(erro && erro.length > 0) {
                erros[key] = erro;
            }
        });
        return erros;
    }
}