export default class ProcessadorTelaFormulario {
    constructor({processadorAtribuicao, processadorFluxos, processadorMapeamento, processadorAnexo}) {
        this.processadorAtribuicao = processadorAtribuicao;
        this.processadorFluxos = processadorFluxos;
        this.processadorMapeamento = processadorMapeamento;
        this.processadorAnexo = processadorAnexo;
    }

    processar({dados, tela, atribuicoes}) {
        this.processadorMapeamento.processar({dados, tela, atribuicoes});
        if(tela.atribuicoes) {
            tela.atribuicoes.forEach(atribuicao => {
                this.processadorAtribuicao.processar({dados, atribuicao, atribuicoes});
            });
        }

        this.processadorAnexo.processar({dados, tela, atribuicoes});

        return this.processadorFluxos.processar({dados, fluxos: tela.fluxos});
    }
}