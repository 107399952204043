<template>
    <v-card
        class="pa-2 mt-1 mb-2 d-flex flex-column align-strech flat"
        outlined
    >
        <input type="file" :accept="campo.extensoes" hidden ref="filePicker" />
        <!-- Área Superior -->
        <div class="d-flex flex-column align-center">
            <h3>{{ campo.titulo }}</h3>
            <div v-if="campo.modelo">
                <a :href="campo.modelo">Clique aqui</a> para baixar um modelo
            </div>
            <v-btn
                tile
                color="primary"
                class="pa-6 my-2"
                @click="$refs.filePicker.click()"
                :disabled="!fileInputEnabled"
            >
                Escolher arquivo <v-icon class="ml-2">mdi-cloud</v-icon>
            </v-btn>
        </div>
        <!-- Status -->
        <div class="grey--text">
            <div>{{ msgEscolhidos }}</div>
            <div v-if="campo.tamMaxPorArquivoMb">
                Tamanho máximo por arquivo: {{ campo.tamMaxPorArquivoMb }}MB
            </div>
            <div v-if="campo.tamMaxTotalMb">
                Tamanho máximo: {{ totalTamanhoMb.toPrecision(2) }}MB de
                {{ campo.tamMaxTotalMb }}MB
            </div>
        </div>
        <!-- Área de arquivos em Upload -->
        <file-loader
            v-if="filaParaCarregar.length > 0"
            :fila="filaParaCarregar"
            @arquivoCarregado="arquivoCarregado"
            @envioCancelado="envioCancelado"
            @falha="falhaAoCarregar"
            class="align-strech"
        />
        <!-- Arquivos Carregados -->
        <uploaded-file
            v-for="arquivo in arquivos"
            :key="arquivo.nome"
            :arquivo="arquivo"
            @apagar="apagarArquivo"
        />
        <!-- Dialogo de Erro -->
        <alerta-modal v-model="dialogoErro" tipo="erro">
            {{ msgErro }}
        </alerta-modal>
    </v-card>
</template>

<script>
import FileLoader from "./caixaDeAnexo/FileLoader";
import UploadedFile from "./caixaDeAnexo/UploadedFile";
import AlertaModal from "@/componentes/AlertaModal";

export default {
    name: "caixa-de-anexo",
    components: {
        "file-loader": FileLoader,
        "uploaded-file": UploadedFile,
        "alerta-modal": AlertaModal,
    },
    props: {
        campo: {
            required: true,
            type: Object,
        },
        arquivos: {
            required: true,
        },
    },
    model: {
        prop: "arquivos",
        event: "change",
    },
    data() {
        return {
            filaParaCarregar: [],
            dialogoErro: false,
            msgErro: "",
        };
    },
    methods: {
        arquivoJaCarregado: function (arquivos) {
            return Array.from(arquivos).some((arquivo) => {
                const existeNaFila = this.filaParaCarregar.some(
                    (item) => item.name === arquivo.name
                );
                const existeNosCarregados = this.arquivos.some(
                    (item) => item.name === arquivo.name
                );

                return existeNaFila || existeNosCarregados;
            });
        },
        validarArquivos: function (arquivos) {
            if (this.arquivoJaCarregado(arquivos)) {
                this.exibirErro("Arquivo já foi carregado");
                return false;
            }
            if (this.campo.tamMaxTotalMb) {
                const tamNovosArquivos =
                    Array.from(arquivos).reduce(
                        (acc, item) => acc + item.size,
                        0
                    ) * 0.000001;
                const novoTamanho = tamNovosArquivos + this.totalTamanhoMb;
                if (novoTamanho > this.campo.tamMaxTotalMb) {
                    this.exibirErro("Limite de tamaho excedido");
                    return false;
                }
            }
            return true;
        },
        escolherArquivos: function (arquivos) {
            if (this.validarArquivos(arquivos)) {
                this.filaParaCarregar.push(...arquivos);
            }
        },
        arquivoCarregado: function (arquivo) {
            this.filaParaCarregar.shift();
            this.arquivos.push(arquivo);
        },
        envioCancelado: function (arquivo) {
            const index = this.filaParaCarregar.findIndex(
                (item) => arquivo.name === item.name
            );
            this.filaParaCarregar.splice(index, 1);
        },
        exibirErro: function (msg) {
            this.msgErro = msg;
            this.dialogoErro = true;
        },
        falhaAoCarregar: function () {
            const arquivos = this.filaParaCarregar.splice(0, 1);
            const msg = `O Arquivo '${arquivos[0].name}' já foi carregado`;
            this.exibirErro(msg);
        },
        apagarArquivo: function (arquivo) {
            const index = this.arquivos.findIndex(
                (item) => arquivo.name === item.name
            );
            this.arquivos.splice(index, 1);
        },
    },
    computed: {
        totalArquivos: function () {
            const totalCarregados = this.arquivos ? this.arquivos.length : 0;
            const totalNaFila = this.filaParaCarregar
                ? this.filaParaCarregar.length
                : 0;
            return totalCarregados + totalNaFila;
        },
        totalTamanhoMb: function () {
            let totalBytes = 0;
            if (this.arquivos) {
                totalBytes += this.arquivos.reduce(
                    (acc, item) => acc + item.size,
                    0
                );
            }
            if (this.filaParaCarregar) {
                totalBytes += this.filaParaCarregar.reduce(
                    (acc, item) => acc + item.size,
                    0
                );
            }
            const totalMb = totalBytes * 0.000001;
            return totalMb;
        },
        fileInputEnabled: function () {
            let checkNumero = true;
            if (this.campo.maximo) {
                checkNumero = this.totalArquivos < this.campo.maximo;
            }

            let checkTamanho = true;
            if (this.campo.tamMaxTotalMb) {
                checkTamanho = this.totalTamanhoMb < this.campo.tamMaxTotalMb;
            }

            return checkNumero && checkTamanho;
        },
        msgEscolhidos: function () {
            let msg =
                this.totalArquivos === 1
                    ? "1 arquivo escolhido"
                    : `${this.totalArquivos} arquivos escolhidos`;
            if (this.campo.minimo || this.campo.maximo) {
                msg = msg + " de um";
                if (this.campo.minimo) {
                    msg = msg + ` mínimo de ${this.campo.minimo}`;
                    if (this.campo.maximo) {
                        msg = msg + " e ";
                    }
                }
                if (this.campo.maximo) {
                    msg = msg + ` máximo de ${this.campo.maximo}`;
                }
            }
            return msg;
        },
    },
    mounted() {
        if (!this.arquivos) {
            this.$emit("change", []);
        }
        this.$refs.filePicker.onchange = (event) => {
            this.escolherArquivos(event.target.files);
            event.target.value = "";
        };
    },
};
</script>
