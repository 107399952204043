import Vue from "vue";
import Vuex from "vuex";

import autenticacao from "./modules/autenticacao";
import atendimento from "./modules/atendimento";
import visualizacoes from "./modules/visualizacoes";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
    modules: {
        atendimento,
        autenticacao,
        visualizacoes,
    },
    strict: debug,
});
export default store;
