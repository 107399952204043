<template>
    <div>
        <div v-show="!loading">
            <h1>{{ titulo }}</h1>
            <div v-html="innetData" id="faq-id"></div>
        </div>
        <div v-if="loading" class="ufv-menu-loading d-flex justify-center">
            <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
            />
        </div>
    </div>
</template>

<script>
import { getInstance } from "@/api/ApiFactory";

const api = getInstance();

export default {
    props: {
        faqUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            titulo: "",
            innetData: "",
            anexos: [],
        };
    },
    mounted() {
        this.getFaq();
    },
    methods: {
        getFaq() {
            api.get(this.faqUrl).then((response) => {
                this.titulo = response.data.FAQItem[0].Title;
                this.innetData = response.data.FAQItem[0].Field3;
                this.anexos = response.data.FAQItem[0].Attachment;
                this.loading = false;
            });
        },
        async replaceImages() {
            let faqDiv = document.getElementById("faq-id");
            let images = await faqDiv.getElementsByTagName("img");
            for (let item of images) {
                this.replaceImage(item);
            }
        },
        replaceImage(img) {
            let fileId = this.getFieldFromSrc("FileID", img.src);
            let content = this.buildContent(fileId);
            img.src = content;
        },
        getFieldFromSrc(field, src) {
            var objUrl = new URL(src);
            var params = new URLSearchParams(objUrl.search);
            var action = params.get("Action");
            let parts = action.split(";");
            for (let part of parts) {
                let item = part.split("=");
                if (item.length === 2) {
                    if (item[0] === field) {
                        return item[1];
                    }
                }
            }
            return null;
        },
        buildContent(fileId) {
            let imgData = this.anexos.find((el) => el.FileID === fileId);
            let content = imgData.Content;
            let contentType = imgData.ContentType.split(";")[0];
            return `data:${contentType};base64,${content}`;
        },
    },
    watch: {
        innetData() {
            this.replaceImages();
        },
    },
};
</script>

<style>
.ufv-menu-loading {
    margin-bottom: 5%;
}
</style>
