<template>
    <div>
        <v-simple-table v-if="!mobile" dense fixed-header>
            <thead>
                <tr>
                    <th>Chamado</th>
                    <th>Título</th>
                    <th>Criado</th>
                    <th>Modificado</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="chamado in chamados"
                    :key="chamado.id"
                    v-bind:class="{
                        'font-weight-black': !chamado.visualizado,
                    }"
                >
                    <td class="d-flex flex-row align-end">
                        <v-icon>{{ icon(chamado.estado) }}</v-icon>
                        <router-link
                            :to="{
                                name: 'DetalheChamado',
                                params: { id: chamado.id },
                            }"
                            class="mx-2"
                            >{{ chamado.tn }}</router-link
                        >
                        <v-icon v-if="!chamado.visualizado" color="yellow" dark
                            >mdi-star</v-icon
                        >
                    </td>
                    <td>{{ limite(chamado.titulo, 50) }}</td>
                    <td>{{ formataData(chamado.criado) }}</td>
                    <td>
                        {{ formataData(chamado.modificado) }}
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <div v-if="mobile">
            <v-card
                class="d-flex mx-0 grey--text text--darken-2 mb-4 py-4 px-2"
                elevation="1"
                v-for="chamado in chamados"
                :key="chamado.id"
            >
                <div>
                    <v-icon class="text-h3 gray--text">{{
                        icon(chamado.estado)
                    }}</v-icon>
                </div>
                <div
                    class="ml-4 flex-grow-1 flex-shrink-0 d-flex flex-column justify-space-around"
                >
                    <div class="d-flex justify-space-between">
                        <div>
                            <router-link
                                :to="{
                                    name: 'DetalheChamado',
                                    params: { id: chamado.id },
                                }"
                                >{{ chamado.tn }}</router-link
                            >
                        </div>
                        <div>Criado: {{ formataData(chamado.criado) }}</div>
                    </div>
                    <div class="d-flex justify-space-between">
                        <div>{{ limite(chamado.titulo) }}</div>
                        <div>
                            Modificado: {{ formataData(chamado.modificado) }}
                        </div>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import utils from "@/utils/general"; // formataData, limite, etc....

const iconsMap = {
    aberto: "mdi-email-open-outline",
    novo: "mdi-email-outline",
    "pendente-cliente": "mdi-email-alert-outline",
    fechado: "mdi-email-check-outline",
    pendente: "mdi-email-sync-outline",
    default: "mdi-email-outline",
};

export default {
    props: {
        chamados: {
            type: Array,
            required: true,
        },
    },
    computed: {
        mobile: function () {
            return this.$vuetify.breakpoint.smAndDown;
        },
    },
    methods: {
        icon(estado) {
            return iconsMap[estado] || iconsMap["default"];
        },
        ...utils,
    },
};
</script>
