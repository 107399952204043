import { getServicoChamadosInstance } from "../../services/ServicosFactory";

const ATUALIZAR_VISUALIZACOES = "atualizarVisualizacoes";

const state = {
    numAbertosNaoVisualizados: null,
    numFechadosNaoVisualizados: null,
};

const atualizarVisualizacoes = async ({ commit, rootGetters }) => {
    if (!rootGetters["autenticacao/isAuthenticated"]) {
        commit(ATUALIZAR_VISUALIZACOES, { abertos: null, fechados: null });
        return;
    }
    const idUsuario = rootGetters["autenticacao/cpf"];
    const servicoChamados = getServicoChamadosInstance();
    const abertos = await servicoChamados
        .obterNumChamadosNaoVisualizados({
            idUsuario,
            estado: "aberto",
        })
        .then((resp) => resp.data);
    const fechados = await servicoChamados
        .obterNumChamadosNaoVisualizados({
            idUsuario,
            estado: "fechado",
        })
        .then((resp) => resp.data);
    commit(ATUALIZAR_VISUALIZACOES, { abertos, fechados });
};

const visualizarArtigo = ({ commit, rootGetters }, { ticketId, articleId }) => {
    const idUsuario = rootGetters["autenticacao/cpf"];
    const servicoChamados = getServicoChamadosInstance();
    servicoChamados
        .visualizarArtigo({ idUsuario, ticketId, articleId })
        .then(() => {
            atualizarVisualizacoes({ commit, rootGetters });
        });
};

const actions = {
    atualizarVisualizacoes,
    visualizarArtigo,
};

const getters = {
    numAbertosNaoVisualizados: (state) => state.numAbertosNaoVisualizados,
    numFechadosNaoVisualizados: (state) => state.numFechadosNaoVisualizados,
};

const mutations = {
    [ATUALIZAR_VISUALIZACOES]: (state, payload) => {
        state.numAbertosNaoVisualizados = payload.abertos;
        state.numFechadosNaoVisualizados = payload.fechados;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
