/**
 * aplica o mapa de validadores a uma lista de validadores do componente
 */
export function montarValidadores(componente) {
    if(componente.campo.validadores) {
        componente.campo.validadores.forEach(info => {
            let validador = componente.$mapaValidadores(info.tipo);
    
            validador.aplicar({validador: info, componente});

            componente.validadores.push((value) => {
                return validador.validar({
                validador: info,
                valor: value
                });
            });    
        });
    }
    
}

export default { montarValidadores }