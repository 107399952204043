<template>
    <v-card>
        <v-card-title>Inserir item</v-card-title>
        <v-card-text>            
            <v-form ref="form">
                <div v-for="(campo, nome) in campos" :key="nome">
                    <component :is="$mapaCampos(campo.tipo)" v-model="valores[nome]" :campo="campo"/>
                </div>
            </v-form>                        
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outilined text color="primary" @click="salvar()">Salvar</v-btn>
            <v-btn outilined text @click="$emit('cancelar')">Cancelar</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'painel-insercao',
    props: {
        campos: {
            type: Object,
            required: true
        },
        valores: {
            type: Object,
            required: true
        }
    },
    methods: {
        validarCampos() {
            return this.$refs.form.validate();
        },
        salvar() {
            if(this.validarCampos()) {
                this.$emit('salvar', this.valores);
            }            
        }
    }
};
</script>
