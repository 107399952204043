<template>
    <div class="d-flex justify-space-between my-2">
        <div class="align-self-center">
            <v-icon class="text-h3 gray--text">mdi-file</v-icon>
        </div>
        <div class="flex-grow-1 mx-2">
            <div>{{ truncate(arquivo.name) }}</div>
            <div>{{ toMega(arquivo.size) }}</div>
        </div>
        <div class="align-self-center">
            <v-icon class="text-h3 gray--text" @click="apagar">mdi-delete</v-icon>
        </div>
    </div>
</template>

<script>
export default {
    name: 'uploaded-file',
    props: {
        arquivo: {
            required: true,
            type: File
        }
    },
    emits: ['apagar'],
    methods: {
        toMega: function(value) {
            return (value * 0.000001).toFixed(2) + "MB"; 
        },
        truncate: function(text) {
            if(text.length >= 40) {
                return text.slice(0, 38) + "...";
            } else {
                return text;
            }
        },
        apagar: function() {
            this.$emit('apagar', this.arquivo);
        }
    }
};
</script>
