<template>
  <v-container class="pa-0 my-8">
    <h3>{{ campo.rotulo }}</h3>
    <p v-if="campo.ajuda">{{ campo.ajuda }}</p>
    <v-checkbox v-model="selecionados" :value="opcao.valor" :label="opcao.texto"
        v-for="opcao in campo.opcoes" :key="opcao.nome" @change="update"
        class="pa-0 ma-0"
    />
    <v-divider></v-divider>
  </v-container>
</template>

<script>
export default {
    name: 'caixa-de-checagem',
    props: {
      value: {        
        reqiured: false       
      },
      campo: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        selecionados: []
      }
    },
    mounted() {
        this.inicializarValores();
    },
    methods: {
        inicializarValores() {
            if(!(this.value instanceof Array)) {
                this.selecionados = this.campo.opcoes
                    .filter(opcao => opcao.marcado)
                    .map(opcao => opcao.valor);                
            } else {
              this.selecionados = [...this.value];
            }
            this.$emit("input", this.selecionados);      
        },
        update() {
            this.$emit("input", this.selecionados);
        }
    }
}
</script>

<style>

</style>