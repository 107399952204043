<template>
    <div>
        <item-loader  v-for="(item, index) in fila" :key="item.name" :item="item" :pausado="pauseList[index]"
            @itemCancelado="itemCancelado" @itemCarregado="itemCarregado" @erro="itemFalha"
        />
    </div>
</template>

<script>
import ItemLoader from './ItemLoader.vue';

export default {
    name: 'file-loader',
    components: {
        'item-loader': ItemLoader
    },
    props: {
        fila: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            pauseList: []
        };
    },
    emits: ['arquivoCarregado', 'envioCancelado', 'falha'],
    methods: {
        updatePauseList: function() {
            this.pauseList.length = 0;
            this.fila.forEach((item, index) => {
                this.pauseList.push(index !== 0);
            });
        },
        itemCarregado: function(item) {
            this.$emit('arquivoCarregado', item);
            this.updatePauseList();
        },
        itemCancelado: function(item) {
            this.$emit('envioCancelado', item);
        },
        itemFalha: function(event) {
            this.$emit('falha', event);
        }
    },
    beforeMount() {
        this.updatePauseList();
    }
}
</script>
