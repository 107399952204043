export default class ProcessadorCondicaoComposta {
    constructor({mapaCondicoes}) {
        this.mapaCondicoes = mapaCondicoes;
    }

    avaliarSubcondicao({dados, condicao}) {
        let tipo = condicao.tipo;
        let processador = this.mapaCondicoes[tipo];
        return processador.avaliar({dados, condicao});
    }

    avaliar({dados, condicao}) {
        if(condicao.condicoes === undefined || condicao.condicoes === null) {
            throw new Error('O campo condições deve não pode ser nulo.');
        }

        switch(condicao.operador) {
            case 'or':
                return condicao.condicoes
                .map(item => this.avaliarSubcondicao({dados, condicao: item}))
                .reduce((acc, corrente) => acc || corrente, false);
            case 'and':
                return condicao.condicoes
                .map(item => this.avaliarSubcondicao({dados, condicao: item}))
                .reduce((acc, corrente) => acc && corrente, true);
            case 'not':
                return !this.avaliarSubcondicao({dados, condicao: condicao.condicoes[0]});
            default:
                throw new Error(`O operador ${condicao.operador} não é válido.`);
        }        
    }
}