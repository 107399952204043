
export default class Historico {
    constructor() {
        this.itens = [];
    }

    podeVoltar() {
        return this.itens.length > 0;
    }

    voltar() {
        if(this.podeVoltar()) {
            return this.itens.pop();
        }        
    }

    inserir(item) {
        this.itens.push(item);
    }
}