import ConfigMapaCampos from "@/config/ConfigMapaCampos";
import ServicoAtendimento from "@/services/ServicoAtendimento";
import { getServicoChamadosInstance } from "../services/ServicosFactory";
import ConfigMapaTelas from "../config/ConfigMapaTelas";
import FabricaProcessadores from "../config/fabricas/FabricaProcessadores";
import ConfigVerificador from "../config/ConfigVerificador";
import ConfigMapaValidadores from "../config/ConfigMapaValidadores";
import { getInstance as apiGetInstance } from "../api/ApiFactory";

/**
 * Acomoda todos as configurações relativas ao sistema neste plugin. Cada parte configuravel no sistema possui uma
 * classe correspondente no pacote 'config'.
 */
export default {
    install(app, { store, router }) {
        const configMapaCampos = new ConfigMapaCampos();
        configMapaCampos.configurar(app);

        const fabricaProcessadores = new FabricaProcessadores();
        const configMapaTelas = new ConfigMapaTelas({ fabricaProcessadores });
        const mapaProcessadores = configMapaTelas.configurar(app);

        app.prototype.$servicoAtendimento = new ServicoAtendimento({
            store,
            router,
            mapaProcessadores,
            api: apiGetInstance(),
        });
        app.prototype.$servicoChamados = getServicoChamadosInstance();

        const configVerificador = new ConfigVerificador();
        configVerificador.configurar(app);

        const configMapaValidadores = new ConfigMapaValidadores();
        configMapaValidadores.configurar(app);
    },
};
