<template>
    <v-hover v-slot="{ hover }">
        <v-card
            outlined
            :elevation="hover ? 12 : 1"
            :class="{ 'on-hover': hover }"
            @click="iniciarAtendimento()"            
        >
            <div class="d-flex align-center">
                <v-avatar class="ma-3" size="80" tile>
                    <v-icon size="60">
                        mdi-file-document-multiple-outline
                    </v-icon>
                </v-avatar>
                <v-divider vertical inset></v-divider>
                <div>
                    <v-card-title
                        class="text-h5 card-arvore"
                        v-text="arvore"
                    ></v-card-title>
                </div>
            </div>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "atendimento-card",

    props: {
        arvore: {
            type: String,
            required: true,
        },
    },
    methods: {
        async iniciarAtendimento() {    
            this.$servicoAtendimento.iniciarAtendimento(this.arvore);
        },
    },
};
</script>
<style scoped>
.v-divider--vertical {
    margin-bottom: 20px;
    margin-top: 20px !important;
}

.card-arvore {
    word-break: keep-all;    
}
</style>