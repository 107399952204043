export default class ProcessadorCondicaoSimples {
    constructor({mapaOperandos}) {
        this.mapaOperandos = mapaOperandos;
    }

    calcularValor({operando, dados}) {
        let tipoOp = operando.tipo;
        let processadorOperando = this.mapaOperandos[tipoOp];
        let valor = processadorOperando.calcularValor({operando, dados});
        
        return valor;
    }

    avaliarCondicao({valor1, valor2, comparador}) {
        switch(comparador) {
            case "<":
                return valor1 < valor2;
            case "<=":
                return valor1 <= valor2;
            case "=":
                return valor1 === valor2;
            case ">=":
                return valor1 >= valor2;
            case ">":
                return valor1 > valor2;
            case "!=":
                return valor1 !== valor2;
            default:
                throw new Error(`${comparador} inválido.`);
        }
    }

    avaliar({dados, condicao}) {
        let valor1 = this.calcularValor({
            operando: condicao.operando1,
            dados
        });
        let valor2 = this.calcularValor({
            operando: condicao.operando2,
            dados
        });
        
        let comparador = condicao.comparador;
        return this.avaliarCondicao({valor1, valor2, comparador});
    }
}